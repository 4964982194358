// import logo from "assets/images/brands/brand-main-sm.png";
// import sidebar from "assets/images/brands/Exinitic.png";
import logo from "assets/images/brands/logo192.jpg";

export const clientName = "MerajFx";
export const developedBy = "";
export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL;
export const cpUrl = process.env.REACT_APP_CP_URL;
export const apiUrl = process.env.REACT_APP_API_CP_DOMAIN;
export const companyName =  "MerajFx";
export const companyWebsite = "";
export const privacyPolicyLink = "#";
export const clientAgreementLink = "#";
export const sidebarLogo = logo;
export const smLogo = logo;
export const mainLogo = logo;
export const lightLogo = logo;
